export const addPreloader = () => {
  const container = document.getElementById('single-spa-module-container');

  if (!container) {
    return;
  }

  const div = document.createElement('div');
  const innerDiv = document.createElement('div');

  div.classList.add('UIKitAppPreloader');
  innerDiv.classList.add('UIKitAppPreloader__indeterminate');

  div.appendChild(innerDiv);
  container.appendChild(div);
};
