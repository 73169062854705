export const exampleNodeFullInfo = {
  node: {
    id: 41,
    type: 'HALL',
    name: 'nodeNameTest',
    uid: 'nodeUidTest',
  },
  parentUid: 'parentUidTest',
  availableCurrencies: [],
};

// ToDo Переработать при появлении профиля пользователя
export const exampleAppsData = () => {
  return {
    user: {
      account: {
        id: localStorage.getItem('userId'),
        email: 'userAccountEmail',
        name: 'userAccountName',
        lastName: 'userAccountLastName',
        login: 'userAccountLogin',
        registrationDate: 'userAccountRegistrationDate',
        status: 'Active',
        type: 'User',
      },
      permissions: ['permissions'],
    },
    token: '2B9NR6_vDoBjYs-ZOE5Vf1V2NoaGOstWRLeuIHO5E3f4JICJVbdHwUW8Lrf48t-S',
    currentNode: exampleNodeFullInfo,
  };
};
