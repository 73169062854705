import { keycloakService } from '@ga/login';
import { start } from 'single-spa';

import { registerApplications } from './registerApplications';

import 'antd/dist/reset.css';
import 'shared/styles/common.scss';

const checkAuthAndStart = async () => {
  // Initialize Micro-Frontends
  registerApplications();

  // Init Keycloak provider and login automatically
  await keycloakService.init();

  // Run Single SPA
  start({ urlRerouteOnly: true });
};

checkAuthAndStart();
