import startsWith from 'lodash-es/startsWith';
import { getAppNames, registerApplication } from 'single-spa';

import { addPreloader, exampleAppsData } from 'shared/utils';

export const registerApplications = () => {
  registerApplication({
    name: '@GIS/header',
    app: (_) =>
      Promise.resolve().then(() => {
        // addPreloader(); // ToDo: заменить Preloader
        return System.import(`@GIS/header`);
      }),
    activeWhen: () => true,
    customProps: {
      domElementGetter: () => document.getElementById('single-spa-header'),
    },
  });

  registerApplication({
    name: '@GIS/left-menu',
    app: (_) =>
      Promise.resolve().then(() => {
        // addPreloader(); // ToDo: заменить Preloader
        return System.import(`@GIS/left-menu`);
      }),
    activeWhen: () => true,
    customProps: {
      domElementGetter: () => document.getElementById('single-spa-left-menu'),
    },
  });

  registerApplication({
    name: '@GIS/node-tree',
    app: (_) =>
      Promise.resolve().then(() => {
        addPreloader(); // ToDo: заменить Preloader
        return System.import(`@GIS/node-tree`);
      }),
    activeWhen: () => true,
    customProps: {
      domElementGetter: () => document.getElementById('single-spa-node-tree'),
    },
  });

  if (!getAppNames().includes('@GIS/journals')) {
    registerApplication({
      name: '@GIS/journals',
      app: (_) =>
        Promise.resolve().then(() => {
          addPreloader();
          return System.import('@GIS/journals');
        }),
      activeWhen: (location) => startsWith(location.pathname, `/journals`),
      customProps: {
        domElementGetter: () => document.getElementById('single-spa-module-container'),
      },
    });
  }

  if (!getAppNames().includes('@GIS/node-management')) {
    registerApplication({
      name: '@GIS/node-management',
      app: (_) =>
        Promise.resolve().then(() => {
          addPreloader();
          return System.import('@GIS/node-management');
        }),
      activeWhen: (location) => startsWith(location.pathname, `/node-management`),
      customProps: {
        domElementGetter: () => document.getElementById('single-spa-module-container'),
      },
    });
  }

  if (!getAppNames().includes('@GIS/bonus')) {
    registerApplication({
      name: '@GIS/bonus',
      app: (_) =>
        Promise.resolve().then(() => {
          addPreloader();
          return System.import('@GIS/bonus');
        }),
      activeWhen: (location) => startsWith(location.pathname, `/bonus`),
      customProps: {
        domElementGetter: () => document.getElementById('single-spa-module-container'),
      },
    });
  }

  if (!getAppNames().includes('@GIS/reports')) {
    registerApplication({
      name: '@GIS/reports',
      app: (_) =>
        Promise.resolve().then(() => {
          addPreloader();
          return System.import('@GIS/reports');
        }),
      activeWhen: (location) => startsWith(location.pathname, `/reports`),
      customProps: {
        domElementGetter: () => document.getElementById('single-spa-module-container'),
      },
    });
  }

  if (!getAppNames().includes('@GIS/betting')) {
    registerApplication({
      name: '@GIS/betting',
      app: (_) =>
        Promise.resolve().then(() => {
          addPreloader();
          return System.import('@GIS/betting');
        }),
      activeWhen: (location) => {
        return startsWith(location.pathname, `/betting`);
      },
      customProps: {
        domElementGetter: () => document.getElementById('single-spa-module-container'),
      },
    });
  }

  window.addEventListener('LoginData', () => {
    const data = () => exampleAppsData();

    if (!getAppNames().includes('@MBO/export-loader')) {
      registerApplication({
        name: '@MBO/export-loader',
        app: (_) => System.import('@MBO/export-loader'),
        activeWhen: () => true,
        customProps: {
          data,
          domElementGetter: () => document.getElementById('export-loader-module-container'),
        },
      });
    }
  });
};
